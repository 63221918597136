
export default function Results() {
    return (
        // style={{display: "none"}}
        <div id="coalition-results" className="mt-8" >
            <h1 className="text-lg font-bold">Determined Coalitions</h1>
            <table className="decorated-table" id="coalitions-table">
                <thead>
                    <tr>
                        <td>
                            Coalition Name
                        </td>
                        <td>
                            Total Votes
                        </td>
                        <td>
                            Banzhaf Power
                        </td>
                        <td>
                            Shapley-Shubik Power
                        </td>
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export function displayPower(power) {
    const table = document.querySelector("#coalitions-table tbody")
    table.innerHTML = ""

    const sorted = power.sort((c1, c2) => (c1.weight > c2.weight) ? -1 : 1)
    sorted.forEach((coalition) => {
        const row = table.insertRow()

        const name = row.insertCell(0)
        const weight = row.insertCell(1)
        const banzhaf = row.insertCell(2)
        const shapleyShubik = row.insertCell(3)

        name.innerText = coalition.name
        weight.innerText = coalition.weight
        banzhaf.innerText = coalition.banzhaf
        shapleyShubik.innerText = coalition.shapley
    })
}