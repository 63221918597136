import {getActiveMode} from "../component/Mode";
import {retrieveGroups} from "./readData";
import {getActiveTable} from "../component/Groups";
import { saveAs } from 'file-saver';

export function exportGroup() {
    const currentMode = getActiveTable()
    const allGroups = retrieveGroups()
    const exportName = document.querySelector("#presetName").value
    let obj = {
        id: exportName,
        name: exportName,
        mode: currentMode,
        groups: allGroups
    }
    console.log("All:")
    console.log(JSON.stringify(obj, null, 2))

    var fileToSave = new Blob([JSON.stringify(obj, null, 2)], {
        type: 'application/json'
    });

// Save the file
    saveAs(fileToSave, `${exportName}.json`);
}