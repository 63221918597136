import Groups from "./Groups";
import Mode from "./Mode";
import Runner from "../logic/runner";
import Results from "./Results";
import Export from "./Export";


export default function Dashboard() {
    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
            <div className="min-h-full">
                <div id="bg-color" />
                <div id="bg-overlay" />
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">Carbon Cap Coalition Application</h1>
                    </div>
                </header>
                <main className="h-4/5">
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 container">
                        {/* Replace with your content */}
                        <div className="px-4 py-6 sm:px-0 container h-full">
                            <div className="rounded-lg px-8 py-8 bg-white shadow-lg border border-1 container h-full">
                                <Groups />
                                <Export />
                                {/*<Mode />*/}
                                <Runner />
                                <Results />
                            </div>
                        </div>
                        {/* /End replace */}
                    </div>
                </main>
            </div>
        </>
    )
}