import {PresetSelector} from "./Presets";

const PopulationTable = () => {
    return (
        <>
            <div id="population-display">
                <table id="table-population" className="decorated-table mt-4 text-md py-4 px-4">
                    <thead>
                    <tr>
                        <td>Group Name</td>
                        <td>Minimum Population</td>
                        {/*<td>Acceptable Carbon Reduction (%)</td>*/}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><input type='text'/></td>
                        <td><input type='number'/></td>
                        {/*<td><input type='number' min='0' max='100'/></td>*/}
                    </tr>
                    </tbody>
                </table>
                <button id="new-group"
                        className="bg-blue-500 hover:bg-blue-700 text-white ml-2 text-sm font-bold py-1 px-2 rounded mt-1"
                        onClick={addGroupRow}
                >New Group
                </button>
            </div>
        </>
    )
}

const EmissionTable = () => {
    return (
        <>
            <div id="emission-display" style={{display: "none"}}>
                <table id="table-emission" className="decorated-table mt-4 text-md py-4 px-4">
                    <thead>
                    <tr>
                        <td>Group Name</td>
                        <td>Minimum Emissions (BtCO2)</td>
                        {/*<td>Acceptable Carbon Reduction (%)</td>*/}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><input type='text'/></td>
                        <td><input type='number'/></td>
                        {/*<td><input type='number' min='0' max='100'/></td>*/}
                    </tr>
                    </tbody>
                </table>
                <button id="preset-load"
                        className="bg-blue-500 hover:bg-blue-700 text-white ml-2 text-sm font-bold py-1 px-2 rounded mt-1"
                        onClick={addGroupRow}
                >New Group
                </button>
            </div>
        </>
    )
}

const GDPTable = () => {
    return (
        <>
            <div id="gdp-display" style={{display: "none"}}>
                <table id="table-gdp" className="decorated-table mt-4 text-md py-4 px-4">
                    <thead>
                    <tr>
                        <td>Group Name</td>
                        <td>Minimum GDP Per Capita (USD)</td>
                        {/*<td>Acceptable Carbon Reduction (%)</td>*/}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><input type='text'/></td>
                        <td><input type='number'/></td>
                        {/*<td><input type='number' min='0' max='100'/></td>*/}
                    </tr>
                    </tbody>
                </table>
                <button id="preset-load"
                        className="bg-blue-500 hover:bg-blue-700 text-white ml-2 text-sm font-bold py-1 px-2 rounded mt-1"
                        onClick={addGroupRow}
                >New Group
                </button>
            </div>
        </>
    )
}

export default function Groups() {
    return (<>
        <h1 className="text-lg font-bold">Group Setup</h1>
        <PresetSelector />
        <p>(or) Define Groups:</p>
        <label htmlFor="tableMode">Group Type:</label>
        <select name="tableMode" id="tableMode" className="ml-1" onChange={
            changeType}>
            <option value="population">Population</option>
            <option value="emission">Emission Caps</option>
            <option value="gdp">GDP</option>

        </select>

        <PopulationTable />
        <EmissionTable />
        <GDPTable />
    </>)
}

export function getActiveTable() {
    return document.querySelector("#tableMode").selectedOptions[0].value
}

function addGroupRow() {
    const activeTable = getActiveTable()
    const table = document.querySelector(`#table-${activeTable} tbody`)
    const row = table.insertRow()
    const name = row.insertCell(0)
    const pop = row.insertCell(1)
    // const cap = row.insertCell(2)

    name.innerHTML = "<input type='text' />"
    pop.innerHTML = "<input type='number' />"
    // cap.innerHTML = "<input type='number' min='0' max='100'/>"
}

export function changeType() {
    const active = getActiveTable()
    switch (active) {
        case "population":
            document.querySelector("#population-display").style.display = "block"
            document.querySelector("#gdp-display").style.display = "none"
            document.querySelector("#emission-display").style.display = "none"
            break;

        case "emission":
            document.querySelector("#population-display").style.display = "none"
            document.querySelector("#gdp-display").style.display = "none"
            document.querySelector("#emission-display").style.display = "block"

            break;

        case "gdp":
            document.querySelector("#population-display").style.display = "none"
            document.querySelector("#emission-display").style.display = "none"
            document.querySelector("#gdp-display").style.display = "block"

            break;

        default:
            break;
    }
}