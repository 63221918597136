import {usePapaParse} from "react-papaparse";
import {getActiveTable} from "../component/Groups";
import {getActiveMode} from "../component/Mode";
import {decorateButtonFailed, decorateButtonReady} from "./runner";
import {DataLabels, Quota} from "../config";
import {banzhafPenrose, shapleyShubik} from "power-index";
import {displayPower} from "../component/Results";


export function retrieveGroups() {
    const mode = getActiveTable()
    console.log(`Retrieving ${mode}`)
    const table = document.querySelector(`#${mode}-display tbody`)
    let groups = []
    for(const row of table.rows) {
        const cells = row.cells
        groups.push({
            name: cells.item(0).firstChild.value,
            qualifier: cells.item(1).firstChild.value,
            // acceptable: cells.item(2).firstChild.value,
        })
    }
    return groups
}

export function readData(csvData) {
    // very cringe but idc
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { readString } = usePapaParse();
    readString(csvData, {
        header: true,
        worker: true,
        complete: (results) => {
            processData(results.data.filter(d => d["Country"] !== "World"), retrieveGroups())
        }
    })
}

function processData(results, groups) {
    if(groups.find(g => g.name === "" || g.qualifier === "" || g.acceptable === "")) {
        decorateButtonFailed("Invalid group specified...")
        setTimeout(() => decorateButtonReady(), 5000)
        return
    }
    const tableMode = getActiveTable()
    // const apportionmentMode = getActiveMode()

    let coalitions = new Map()

    let sorted = groups.sort((a, b) => (parseInt(a.qualifier) > parseInt(b.qualifier)) ? -1 : 1)

    results.forEach((country) => {
        const threshold = parseInt(country[DataLabels[tableMode]].replace(/,/g, ""))
        const foundGroup = sorted.find((group) => threshold >= parseInt(group.qualifier))
        const effective = foundGroup ? foundGroup.name : "Unorganized"
        if(!coalitions.has(effective)) {
            coalitions.set(effective, [])
        }
        coalitions.get(effective).push(country)
    })

    const coalitionPower = computePower(coalitions)

    displayPower(coalitionPower)
    decorateButtonReady()
}

function computePower(coalitions) {

    const entries = [...coalitions].map(([name, value]) => ({name, value}))
    const weights = entries.map((e) => e.value.length)

    const banzhaf = banzhafPenrose(weights, Quota)
    const shapley = shapleyShubik(weights, Quota)

    let results = []

    for(let i = 0; i < entries.length; i++) {
        const entry = entries[i]
        const weight = weights[i]
        const banzhafPower = banzhaf[i]
        const shapleyShubik = shapley[i]

        results.push({
            name: entry.name,
            weight: weight,
            banzhaf: banzhafPower,
            shapley: shapleyShubik
        })
    }
    return results
}