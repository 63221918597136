import {useEffect} from "react";
import {Presets} from "../config";
import {changeType, getActiveTable} from "./Groups";


export const PresetSelector = () => {
    useEffect(() => {
        const selector = document.querySelector('#preset')
        selector.innerHTML = ""
        Presets.forEach((preset) => {
            const option = document.createElement("option")
            option.value = preset.id
            option.text = preset.name
            selector.add(option)
        })
    }, [])
    return (
        <>
            <form>
                <label htmlFor="preset">Load a preset:</label>
                <select name="preset" id="preset" className="ml-1">
                </select>
                <a id="load-preset"
                   className="bg-blue-500 hover:bg-blue-700 text-white ml-2 text-sm font-bold py-1 px-2 rounded"
                   onClick={updatePreset}
                >Load Preset
                </a>
            </form>

        </>
    )
}

function updatePreset() {
    const preset = document.querySelector("#preset").selectedOptions[0].value
    const found = Presets.find((option) => option.id === preset)
    if(found) {
        console.log("Updating to preset " + JSON.stringify(found))
        document.querySelector("#tableMode").value = found.mode
        changeType()
        const table = document.querySelector(`#table-${found.mode} tbody`)
        // clear table
        table.innerHTML = ""
        found.groups.forEach(group => {
            const row = table.insertRow()
            const name = row.insertCell(0)
            const val = row.insertCell(1)
            // const cap = row.insertCell(2)

            name.innerHTML = `<input type='text' value="${group.name}"/>`
            val.innerHTML = `<input type='number' value='${group.qualifier}'/>`
            // cap.innerHTML = `<input type='number' min='0' max='100' value="${group.acceptable}"/>`
        })
    }
}