export const Presets = [
    {
        "id": "World Bank Country Classifications",
        "name": "World Bank Country Classifications",
        "mode": "gdp",
        "groups": [
            {
                "name": "High Income",
                "qualifier": "12695"
            },
            {
                "name": "Upper-Middle Income",
                "qualifier": "4096"
            },
            {
                "name": "Lower-Middle Income",
                "qualifier": "1046"
            },
            {
                "name": "Lower Income",
                "qualifier": "0"
            }
        ]
    },
    {
        "id": "em1",
        "name": "Emission (scenario 1)",
        "mode": "emission",
        "groups": [
            {
                "name": "Largest",
                "qualifier": "1000"
            },
            {
                "name": "Large",
                "qualifier": "100"
            },
            {
                "name": "Medium",
                "qualifier": "10"
            },
            {
                "name": "Small",
                "qualifier": "1"
            },
            {
                "name": "Smallest",
                "qualifier": "0"
            }
        ]
    },
    {
        "id": "em2",
        "name": "Emission (Scenario 2)",
        "mode": "emission",
        "groups": [
            {
                "name": "Largest",
                "qualifier": "100"
            },
            {
                "name": "Large",
                "qualifier": "10"
            },
            {
                "name": "Medium",
                "qualifier": "1"
            },
            {
                "name": "Small",
                "qualifier": "0"
            }
        ]
    },
    {
        "id": "pop1",
        "name": "Population (Scenario 1)",
        "mode": "population",
        "groups": [
            {
                "name": "Largest",
                "qualifier": "50000000"
            },
            {
                "name": "Large",
                "qualifier": "10000000"
            },
            {
                "name": "Medium",
                "qualifier": "1000000"
            },
            {
                "name": "Small",
                "qualifier": "0"
            }
        ]
    },
    {
        "id": "pop2",
        "name": "Population (Scenario 2)",
        "mode": "population",
        "groups": [
            {
                "name": "Biggest",
                "qualifier": "10000000"
            },
            {
                "name": "Big",
                "qualifier": "5000000"
            },
            {
                "name": "Medium",
                "qualifier": "1000000"
            },
            {
                "name": "Small",
                "qualifier": "100000"
            },
            {
                "name": "Smallest",
                "qualifier": "0"
            }
        ]
    }
]

export const Quota = 144

// TODO: Double check these in case they have changed...
export const DataLabels = {
    population: "Population",
    emission: "Emissions (MtCO₂)",
    gdp: "GDP per capita ($US)",

    per_capita: "Per Capita (MtCO₂)",
    scaled: "Scaled (MtCO₂)",
    augmented: "By Augmented Population"
}