import {exportGroup} from "../logic/exportGroup";


export default function Export() {
    return (<>
        <div className="bg-gray-100 mt-2 container">
            <h1 className="text-lg font-bold" placeholder="Preset Name">Preset Exporter</h1>
            <input type="text" id="presetName" name="presetName"/>
            <button className="bg-sky-500 hover:bg-sky-600 text-white text-md font-bold py-1 px-2 rounded"
                    onClick={exportGroup}>
                Export Preset (to file)
            </button>
        </div>
    </>)
}