import {getActiveMode} from "../component/Mode";
import {readData} from "./readData";

export default function Runner() {
    return (
        <div className="mt-4">
            <button className="bg-green-700 hover:bg-green-900 text-white text-lg font-bold py-2 px-4 rounded" onClick={calculate} id="runButton">
                Generate Coalitions
            </button>
        </div>
    )
}

function decorateButtonPending() {
    const button = document.querySelector("#runButton")
    button.innerText = "Downloading Data..."
    button.className = "bg-yellow-500 text-white text-lg font-bold py-2 px-4 rounded"
}

export function decorateButtonFailed(reason) {
    const button = document.querySelector("#runButton")
    button.innerText = reason
    button.className = "bg-red-500 text-white text-lg font-bold py-2 px-4 rounded"
}

export function decorateButtonReady() {
    const button = document.querySelector("#runButton")
    button.innerText = "Generate Coalitions"
    button.className = "bg-green-700 hover:bg-green-900 text-white text-lg font-bold py-2 px-4 rounded"
}

function decorateButtonProcessing() {
    const button = document.querySelector("#runButton")
    button.innerText = "Processing Data..."
    button.className = "bg-sky-700 hover:bg-sky-900 text-white text-lg font-bold py-2 px-4 rounded"
}


function calculate() {
    decorateButtonPending()

    // const mode = getActiveMode()

    const request = new XMLHttpRequest()

    const id = "1gm7QmhDapbeM--Sjpq2bo1suWdLAKh30"
    const sheetName = "Apportionment"
    request.open('GET', `https://docs.google.com/spreadsheets/d/${id}/gviz/tq?tqx=out:csv&sheet=${sheetName}`, true)
    request.setRequestHeader("Content-Type", "text/csv");

    request.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            decorateButtonProcessing()
            readData(this.responseText)
        } else if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
            decorateButtonFailed("Failed to download data...")
        }
    }
    request.send(null)
}